import type { FC } from 'react'

import type { BenefitsWithImageType } from '@Types/blocks/benefitsWithImage'
import type { ExtendBlocksType } from '@Types/blocks/blocks'

import Card from './components/Card'
import { Container } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BenefitsWithImages: FC<Props> = ({ data }) => {
  const { benefits } = data as BenefitsWithImageType

  return (
    <Container>
      {benefits.map((item, index) => (
        <Card count={index + 1} itemId={item} key={item} />
      ))}
    </Container>
  )
}
