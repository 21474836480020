import BodyText from 'firebird-ui/src/components/BodyText'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { flexCenter } from '@styles/basic/flex'

export const StyledLink = styled(Link)`
  width: auto;
`

export const Container = styled.div<{ $bgUrl?: string; $isFirst: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  background: url(${p => p.$bgUrl}) no-repeat center center;
  background-size: 100% 100%;
  min-height: 328px;
  min-width: 328px;
  width: 100%;
  max-width: ${p => (p.$isFirst ? '100%' : '328px')};
  border-radius: ${p => p.theme.borderRadius.sm};
  padding-block: ${p => (p.$isFirst ? 56 : 24)}px;

  ${p => p.theme.mediaQueries.desktopXS} {
    padding-block: 24px;
  }
`

export const Mask = styled.div<{ $isFirst: boolean }>`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    ${p => (p.$isFirst ? 'to bottom,' : 'to top,')} ${p => p.theme.colors.black0},
    ${p => p.theme.colors.black100}
  );
`

export const Content = styled.div<{ $isFirst: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-inline: ${p => (p.$isFirst ? 80 : 32)}px;
  margin-top: ${p => (p.$isFirst ? 'auto' : 'inherit')};
  row-gap: 12px;
  z-index: 2;

  ${p => p.theme.mediaQueries.desktopXS} {
    padding-inline: ${p => (p.$isFirst ? '32px 24px' : '32px 8px')};
  }

  ${p =>
    p.$isFirst &&
    css`
      &:before {
        position: absolute;
        left: 0;
        content: '';
        height: 100%;
        width: 8px;
        background: ${p => p.theme.colors.yellow10};
      }
    `}
`

export const Title = styled.span<{ $isFirst: boolean }>`
  ${p => p.theme.typography.desktop.heading.small.$value};
  font-weight: 500;
  color: ${p => p.theme.colors.white};
`

export const Text = styled(BodyText)`
  color: ${p => p.theme.colors.white};
`

export const Circle = styled.div<{ $isFirst: boolean }>`
  ${flexCenter};
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.yellow10};

  svg {
    fill: ${p => p.theme.colors.white};
    transform: rotate(-135deg);
  }

  ${p =>
    !p.$isFirst &&
    css`
      top: inherit;
      bottom: 32px;
      right: 32px;
    `}
`
