import type { FC } from 'react'

import { useGetParagraphQuery } from '@redux/features/homePage/api'

import ArrowDownIcon from '@iconscout/unicons/svg/line/arrow-down.svg'

import { Circle, Container, Content, Mask, StyledLink, Text, Title } from './styles'

type Props = {
  count: number
  itemId: number
}

export const Card: FC<Props> = ({ count, itemId }) => {
  const { data } = useGetParagraphQuery(itemId)

  if (!data) return

  const isFirst = count === 1

  return (
    <StyledLink href={data?.link?.uri || '/'}>
      <Container $bgUrl={data?.image?.uri.url} $isFirst={isFirst}>
        <Mask $isFirst={isFirst} />
        <Circle $isFirst={isFirst}>
          <ArrowDownIcon />
        </Circle>
        <Content $isFirst={isFirst}>
          <Title $isFirst={isFirst}>{data?.title}</Title>
          <Text dangerouslySetInnerHTML={{ __html: data?.description || '' }} size="small" />
        </Content>
      </Container>
    </StyledLink>
  )
}
